import React from "react";
import heroImg from "../../assets/images/foto.png";

const Hero = () => {
  return (
    <section className="pt-0" id="about">
      <div className="container pt-14">
        <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
          {/* === hero left content === */}
          <div className="w-full md:basis-1/2">
            <h5
              data-aos="fade-right"
              data-aos-duration="1500"
              className="text-headingColor font-[600] text-[16px]"
            >
              Hello Welcome
            </h5>
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className="text-headingColor font-[800] text-[1.8rem] sm:text-[40ox] leading-[35px] sm:leading-[46px] mt-5"
            >
              <span className="text-textSecondary">Jurus.id</span> <br /> PT.
              JURU <span className="text-textSecondary">URUSAN</span> INDONESIA
            </h1>

            <div
              className="flex items-center gap-6 mt-7"
              data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-delay="200"
            >
              <a href="#contact">
                <button className="bg-headerColor text-white font-[500] flex items-center gap-2 hover:bg-[#25225e]/80 ease-in duration-300 py-2 px-4 rounded-[8px]">
                  <i className="ri-mail-line"></i>Get More
                </button>
              </a>
              <a
                href="#portfolio"
                className="text-smallColor font-[600] text-[16px] border-b border-solid border-smallTextColor"
              >
                See Project
              </a>
            </div>
            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              className="flex gap-2 text-headingColor font-[500] mt-14 text-[15px] leading-7 sm:pl-14 sm:pr-10"
            >
              <span>
                <i className="ri-apps-line"></i>
              </span>
              Bussines application devplopment, Jasa pengembangan aplikasi
              dimulai Analisis Proses Bisnis hingga implementasi. Merencanakan
              dan mengembangkan aplikasi Backend Devlopment, Frontend
              Devlopment, UI/UX Design dan Apps Devlopment.
            </p>

            <div className="flex items-center gap-9 mt-14">
              <span className="text-smallTextColor text-[15px] font-[600]">
                Follow Us:
              </span>
              <span>
                <a
                  href="#youtube"
                  className="text-smallTextColor text-[16px] font-[600]"
                >
                  <i className="ri-youtube-line"></i>
                </a>
              </span>
              <span>
                <a
                  href="#facebook"
                  className="text-smallTextColor text-[16px] font-[600]"
                >
                  <i className="ri-facebook-line"></i>
                </a>
              </span>
              <span>
                <a
                  href="#intagram"
                  className="text-smallTextColor text-[16px] font-[600]"
                >
                  <i className="ri-instagram-line"></i>
                </a>
              </span>
            </div>
          </div>
          {/* === hero left end === */}
          {/* === hero img === */}
          <div className="w-full self-end px-4 lg:w-1/2">
            <figure className="relative mt-10 lg:mt-9 lg:right-0">
              <img src={heroImg} alt="" className="max-w-full mx-auto" />
              <span className="absolute bottom-20 -z-10 left-1/2 -translate-x-1/2 md:scale-125">
                <svg
                  width="400"
                  height="400"
                  viewBox="0 0 200 200"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#df6738"
                    d="M45.5,-25.4C56.8,-6.7,62.3,16.3,53.8,30.5C45.3,44.8,22.6,50.4,-2.6,51.8C-27.8,53.3,-55.5,50.7,-65.6,35.6C-75.7,20.4,-68.1,-7.3,-54.2,-27.5C-40.3,-47.7,-20.2,-60.4,-1.5,-59.5C17.1,-58.6,34.2,-44.1,45.5,-25.4Z"
                    transform="translate(100 100) scale(1.1)"
                  />
                </svg>
              </span>
            </figure>
          </div>
          {/* === hero img end === */}
          {/* === hero content right === */}
          <div className="md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col md:justify-end md:text-end">
            <div className="mb-10">
              <h2 className="text-textSecondary font-[700] text-[32px]">
                MySQL
              </h2>
              <h4 className="text-headingColor font-[700] text-[32px]">
                NodeJS
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-textSecondary font-[700] text-[32px]">
                ReactJS
              </h2>
              <h4 className="text-headingColor font-[700] text-[32px]">
                React Native
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-textSecondary font-[700] text-[32px]">
                Laravel
              </h2>
              <h4 className="text-headingColor font-[700] text-[32px]">PHP</h4>
            </div>
            <div className="mb-10">
              <h2 className="text-textSecondary font-[700] text-[32px]">
                CSS & HTML
              </h2>
              <h4 className="text-headingColor font-[700] text-[32px]">
                Web Services
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
