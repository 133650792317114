import React from "react";
import frontendImg from "../../assets/images/front-end.png";
import backendImg from "../../assets/images/backend.png";
import uiImg from "../../assets/images/design.png";
import appsImg from "../../assets/images/apps.png";

const Services = () => {
  return (
    <section id="services">
      <div className="coontainer lg:pt-5">
        <div className="text-center">
          <h2 className="text-headingColor font-[800] text-[2.4rem] mb-5">
            Our Services
          </h2>
          <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7">
            Kami mengembangkan solusi bisnis Anda berbasis data dan komputasi
            serta pengkodean perangkat lunak yang berkualitas yang berkolaborasi
            dalam tim.
          </p>
        </div>

        <div className="flex flex-col justify-center sm:py-12">
          <div className="w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
            {/* === vertical line running through the midlle === */}
            <div className="hidden absolute w-1 sm:block bg-headerColor/80 h-[55rem] left-1/2 transform-translate-x-1/2"></div>

            {/* === left card === */}
            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex items-center flex-col sm:flex-row">
                <div className="flex justify-start w-full mx-atuo items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      className="bg-white p-4 rounded shadow group hover:bg-headerColor cursor-pointer ease-in duration-150"
                    >
                      <h3 className="text-headerColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                        Frontend Development
                      </h3>
                      <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                        Mengembangkan tampilan aplikasi dan website dan
                        berkolaborasi memiliki karakter desain sesuai kebutuhan.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="rounded-full bg-headerColor border-whiite border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                  <figure>
                    <img src={frontendImg} alt="" />
                  </figure>
                </div>
              </div>
            </div>

            {/* right card */}
            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex items-center flex-col sm:flex-row">
                <div className="flex justify-end w-full mx-atuo items-center">
                  <div className="w-full sm:w-1/2 sm:pl-8">
                    <div
                      data-aos="fade-left"
                      data-aos-delay="50"
                      data-aos-duration="1300"
                      className="bg-white p-4 rounded shadow group hover:bg-headerColor cursor-pointer ease-in duration-150"
                    >
                      <h3 className="text-headerColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                        Backend Development
                      </h3>
                      <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                        Mengembangkan aplikasi berbasis servis dalam akses dan
                        distribusi data untuk frontend serta integrasi sistem.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="rounded-full bg-headerColor border-whiite border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                  <figure>
                    <img src={backendImg} alt="" />
                  </figure>
                </div>
              </div>
            </div>

            {/* === left card === */}
            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex items-center flex-col sm:flex-row">
                <div className="flex justify-start w-full mx-atuo items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8">
                    <div
                      data-aos="fade-right"
                      data-aos-delay="100"
                      data-aos-duration="1400"
                      className="bg-white p-4 rounded shadow group hover:bg-headerColor cursor-pointer ease-in duration-150"
                    >
                      <h3 className="text-headerColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                        UI/UX Design
                      </h3>
                      <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                        Mengembangkan dan mendesain tampilan aplikasi dan
                        website sesuai dengan analisa dan pengalaman user dalam
                        menyesuaikan kebutuhan bisnis.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="rounded-full bg-headerColor border-whiite border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                  <figure>
                    <img src={uiImg} alt="" />
                  </figure>
                </div>
              </div>
            </div>

            {/* right card */}
            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex items-center flex-col sm:flex-row">
                <div className="flex justify-end w-full mx-atuo items-center">
                  <div className="w-full sm:w-1/2 sm:pl-8">
                    <div
                      data-aos="fade-left"
                      data-aos-duration="1500"
                      className="bg-white p-4 rounded shadow group hover:bg-headerColor cursor-pointer ease-in duration-150"
                    >
                      <h3 className="text-headerColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                        Apps Development
                      </h3>
                      <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                        Mengembangkan aplikasi berbasis smartphone.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="rounded-full bg-headerColor border-whiite border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                  <figure>
                    <img src={appsImg} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
