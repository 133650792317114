import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";

import Home from "./components/UI/Home";
import EcatkerPrivasi from "./components/UI/EcatkerPrivasi";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "ecatker-privacy",
        element: <EcatkerPrivasi />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return <RouterProvider router={router} />;
}
export default App;
