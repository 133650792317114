import React from "react";
import heroImg from "../../assets/images/logo.png";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-[#12141e] pt-12 pb-12">
      {/* === footer top === */}
      <div className="container mx-auto">
        <div className="sm:flex items-center justify-between mg:gap-8">
          <div className="w-full sm:w-1/2">
            <h2 className="text-[26px] leading-10 text-white font-[600] md-5 md:text-[2rem] pb-5">
              Do you want to make beautiful products?
            </h2>
            <a href="#contact">
              <button className="bg-textSecondary text-white font-[500] flex items-center gap-2 hover:bg-textSecondary/80 ease-in duration-300 py-2 px-4 rounded-[8px]">
                <i className="ri-mail-line"></i>Get More
              </button>
            </a>
          </div>

          <div className="w-full sm:w-1/2">
            <p className="text-gray-300 leading-7 mt-4 sm:mt-0 ">
              Jl. TP. Srriwijaya Perumahan Villa Melati Asri Blok C No. C9
              RT.09, Beliung, Alam Barajo, Kota Jambi. Kode Pos: 36124
              <br />
              Email: office@jurus.id
              <br />
              WA: 0822-6996-1671 / 0853-6958-9310
            </p>

            <div className="flex items-center gap-4 flex-wrap md-gap-8 mt-10">
              <span className="text-gray-300 font-600 text-[15px]">
                Follow Us:
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#youtube"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  <i className="ri-youtube-line"></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#facebook"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  <i className="ri-facebook-line"></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#facebook"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  <i className="ri-instagram-line"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div>
          <ul className="flex items-center justify-center gap-10 mt-10">
            <li>
              <a className="text-gray-400 font-[600]" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="text-gray-400 font-[600]" href="#services">
                Services
              </a>
            </li>
            <li>
              <a className="text-gray-400 font-[600]" href="#portfolio">
                Project
              </a>
            </li>
            <li>
              <a className="text-gray-400 font-[600]" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* === footer top end === */}
      {/* === footer bottom === */}
      <div className="bg-[#25225e] py-3 mt-14">
        <div className="container mx-auto">
          <div className="flex items-center justify-center sm:justify-between">
            <div className="hidden sm:block">
              <div className="flex items-center gap-[10px]">
                <figure className="w-12 h-12">
                  <img src={heroImg} alt="" />
                </figure>
              </div>
            </div>
            <div className="text-gray-400 text-[14px]">
              Copyright {year} developed by IT Jurus.id - All right reserved.
            </div>
          </div>
        </div>
      </div>
      {/* ===footer bottom end === */}
    </footer>
  );
};

export default Footer;
