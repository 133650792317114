import React from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Hero from "../UI/Hero";
import Services from "../UI/Services";
import Portfolio from "../UI/Portfolio";
import Contact from "../UI/Contact";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
